import atob from "atob";
import btoa from "btoa";
import { fromJS, Map } from "immutable";
import pluralize from "pluralize";

import SearchHeadingBrands from "components/Search/SearchHeadingBrand";

import * as sortConstants from "constants/sort";
import { prodLog } from "utils/dev";
import { getQueries, getParams, matchesRouteWithParams } from "utils/url";

const SEARCH_RESULTS_PAGE_PATH = "/search/:entity_type/q/:term";
const SEARCH_RESULTS_NO_TERM_PAGE_PATH = "/search/:entity_type";
const ENTITY_INDEX_PAGE_PATH = "/:entity_type/:term";
const CHANGE_URL_MARKER = "$£*^";

// removes the marker from the function above, so it doesn't get includes in the
// search term we actuall show
export const getURLSearchTerm = (term) => {
  if (term) {
    return decodeURIComponent(term.replace(CHANGE_URL_MARKER, ""));
  }

  return term;
};

export const getActiveFilters = (filters, availableFilters) => {
  if (filters && availableFilters) {
    return filters.reduce((filtered, value, key) => {
      if (!availableFilters.has(key)) {
        return filtered.delete(key);
      }

      return filtered;
    }, filters);
  }

  return Map();
};

export const getIsSearchPage = (url) =>
  matchesRouteWithParams(url, SEARCH_RESULTS_NO_TERM_PAGE_PATH);

export const getURLTerm = (url) => {
  const isSearchPage = getIsSearchPage(url);

  if (isSearchPage) {
    const locParams = getParams(url, SEARCH_RESULTS_PAGE_PATH);

    return getURLSearchTerm(locParams.term);
  }

  return null;
};

export const getURLSort = (url) => {
  const isSearchPage = getIsSearchPage(url);

  if (isSearchPage) {
    const searchQueries = getQueries(url);

    return searchQueries.sort;
  }

  const locParams = getParams(url, ENTITY_INDEX_PAGE_PATH);

  return locParams.sort;
};

export const getURLDirection = (url) => {
  const locQueries = getQueries(url);

  return locQueries.dir;
};

export const getURLFiltersTitle = (url) => {
  try {
    const locQueries = getQueries(url);

    if (!locQueries.filterTitle) {
      return null;
    }

    return atob(decodeURIComponent(locQueries.filterTitle));
  } catch (e) {
    prodLog("getURLFiltersTitle - Error decoding filterTitle from URL", e);

    return Map();
  }
};

export const getURLSearchDescription = (url) => {
  const isSearchPage = getIsSearchPage(url);
  const term = getURLTerm(url);
  const sort = getURLSort(url);
  const filtersTitle = getURLFiltersTitle(url);
  const filterTitle = filtersTitle ? `Filtered By ${filtersTitle}` : null;
  const termTitle = term ? `Searched "${term}"` : null;
  const sortConfig = sort
    ? sortConstants.getSortConfig(sortConstants.keysToConstants[sort])
    : null;
  const dir = getURLDirection(url);
  const shouldShowDir =
    dir &&
    ((sortConfig &&
      sortConfig.defaultDirection &&
      sortConfig.defaultDirection !== dir) ||
      (!sortConfig && dir === "asc"));
  const dirTitle = dir === "asc" ? "Acending" : "Decending";
  let sortTitle = sortConfig
    ? `Sort ${
        sortConfig.title.includes("By ")
          ? sortConfig.title
          : `By ${sortConfig.title}`
      }`
    : null;

  if (!sortTitle) {
    sortTitle = isSearchPage ? "Sort By Relevancy" : "Sort By Best";
  }

  return `${termTitle || ""}${termTitle ? `, ${sortTitle}` : sortTitle}${
    filtersTitle ? `, ${filterTitle}` : ""
  }${shouldShowDir ? `, in ${dirTitle} Order` : ""}`;
};

export const getIsCurrentURL = (searchUrl, location) => {
  const searchQueries = getQueries(searchUrl);
  const locQueries = getQueries(location.search);
  const searchPathname = searchUrl ? searchUrl.split("?")[0] : null;
  let pathsMatch = searchPathname && searchPathname === location.pathname;

  if (getIsSearchPage(location.pathname)) {
    // need to account for the CHANGE_URL_MARKER in the searh results term
    const locParams = getParams(location.pathname, SEARCH_RESULTS_PAGE_PATH);
    const searchParams = getParams(searchPathname, SEARCH_RESULTS_PAGE_PATH);
    const searchTerm = getURLSearchTerm(searchParams.term);
    const locTerm = getURLSearchTerm(locParams.term);

    pathsMatch =
      locParams.entity_type === searchParams.entity_type &&
      searchTerm === locTerm;
  }

  return (
    pathsMatch &&
    searchQueries.sort === locQueries.sort &&
    searchQueries.dir === locQueries.dir &&
    searchQueries.filter === locQueries.filter &&
    searchQueries.ranking_period === locQueries.ranking_period
  );
};

export const getSortValuesFromLocation = (location, passedDef) => {
  const def = passedDef || sortConstants.SORT_ORDER_RELEVANCE;

  if (!location) {
    return {
      sort: def,
      dir: "desc",
    };
  }

  const query = location.query || getQueries(location.search) || {};
  const { sort, dir = "desc" } = query;
  let newsort = def;

  if (sort) {
    newsort = sortConstants.keysToConstants[sort];
  }

  return {
    sort: newsort,
    dir,
  };
};

export const getUrlFiltersFromJS = (filters) => {
  if (filters && filters.size > 0) {
    const finalValue = filters?.reduce(
      (final, filter, key) =>
        final?.set(key, fromJS({ value: filter?.get("value") })),
      Map()
    );
    const finalJSON = finalValue.toJSON();
    const filterString = getBase64EncodedObject(finalJSON);

    return filterString;
  }

  return null;
};

export const getBase64EncodedObject = (obj) => btoa(JSON.stringify(obj));

export const getFiltersFromLocation = (filter) => {
  try {
    if (!filter) {
      return;
    }

    const jsFilters = JSON.parse(atob(decodeURIComponent(filter)));

    if (jsFilters.term) {
      jsFilters.term = getURLSearchTerm(jsFilters.term);
    }

    const map = fromJS(jsFilters);

    return map;
  } catch (e) {
    prodLog("getFiltersFromLocation - Error decoding filters from URL", e);

    return Map();
  }
};

export const getHighlightedString = (
  useHighlight,
  plainString,
  markedString,
  className
) => {
  if (!useHighlight || !markedString) {
    return plainString;
  }

  const final = markedString
    .map((m) => m.replace(/<em>/g, `<em class="${className}">`))
    .first();

  return final;
};

export const highlightString = (stringToHighlight, regex, className) => {
  if (stringToHighlight && regex) {
    return stringToHighlight.replace(regex, `<em class="${className}">$&</em>`);
  }

  return stringToHighlight;
};

export const getDescriptionFromFilter = (key, value, allFilters) =>
  allFilters.getIn([key, "title"]);

export const getValueTitle = (availableFilters, key, value) => {
  const matchedOption =
    availableFilters &&
    availableFilters.getIn([key, "options"]) &&
    availableFilters
      .getIn([key, "options"])
      .filter((v) => v.get("value") === value)
      .first();

  return matchedOption && matchedOption.get("title");
};

export const getFilterString = (filters, allFilters) => {
  if (!allFilters) {
    return "";
  }

  const firstFilterString = getDescriptionFromFilter(
    filters.keySeq().first(),
    filters.valueSeq().first(),
    allFilters
  );

  return `${firstFilterString}${
    filters.size > 1 ? ` and ${filters.size - 1} more` : ""
  }`;
};

export const getSortString = (sort, dir) => {
  const foundSort = sortConstants.sortIcons.find((s) => s.id === sort);
  const { title } = foundSort || {};

  return `${title}, in ${dir === "asc" ? "ascending" : "descending"} order`;
};

export const getSearchHeadingFromEntity = (entity_type = "podcast") =>
  ({
    podcast: { title: pluralize("Podcast") },
    episode: { title: pluralize("Episode") },
    tag: { title: pluralize("Category"), hideViewAll: true },
    category: { title: pluralize("Category"), hideViewAll: true },
    userlist: { title: pluralize("List") },
    creator: { title: pluralize("Creator") },
    user: { title: pluralize("User Profile") },
    brand: {
      title: <SearchHeadingBrands />,
      hideViewAll: true,
    },
  }[entity_type.toLowerCase()]);

export const filterOutWords = (str, wordsToFilterOut, replaceString = "") => {
  const rgx = new RegExp(`\\b${wordsToFilterOut.join("\\b|\\b")}\\b`, "gi");

  return str.replace(rgx, replaceString);
};

export const filterOutProfanities = (str) => {
  // TODO maybe move to a package
  const wordsToFilterOut = ["shit", "ass", "fuck", "fucking", "cunt"];

  return filterOutWords(str, wordsToFilterOut, "****");
};

export const getTypeFromSearchResult = (item) =>
  item && item.get("_id") && item.get("_id").split(":")[0];
